<template>
	<div class="container px-6 mx-auto grid dark:bg-gray-900">
		<!-- modal para asociar cuentas -->
		<modal name="associateAccount">
			<div class="p-4 semibold">
				Asociar cuenta
			</div>
			<div class="py-2 px-4 ">
				<div class="text-left mb-3 p-1">
					Selecciona la cuenta a asociar
					<Multiselect 
						v-model="related_account_id" 
						tag-placeholder="Add" 
						placeholder="Cuenta" 
						label="name" track-by="code" 
						:options="objAccountSelectAccounts" 
						:multiple="false" 
						:taggable="true" 
						@tag="addTag"
					></Multiselect>
					
				</div>
				<div class="text-left mb-3 p-1">
					Informacion que provee la cuenta <br>
					<select v-model="subdescriptor" class="border-2 border-gray-300 rounded">
						<!-- <option value=""></option> -->
						<option value="CxB">DISPONIBILIDAD BANCARIA</option>
						<option value="CxC">CUENTAS POR COBRAR</option>
						<option value="CxI">COSTOS INCURRIDOS</option>
						<option value="PxTC">PASIVOS TARJETAS DE CREDITO</option>
						<option value="CxP">CUENTAS POR PAGAR</option>
					</select>
				</div>
				<div class="text-sm w-full text-right">
					<button @click="associateAccount()" class="ml-2 px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-green-600 border border-transparent rounded-lg active:bg-blue-500 hover:bg-green-700 focus:outline-none focus:shadow-outline-purple">
						Asociar
					</button>
				</div>

			</div>
			
		</modal>
		<h2
			class="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200 text-left"
		>
			Estado de un proyecto
		</h2>
		<div class="grid grid-cols-2 gap-1">
			<div class="flex mb-3">
				
				<div class="w-full">
					<!-- <input type="text" v-model="auxiliary" class="border-2 border-gray-300 rounded p-2 w-36" placeholder="Auxiliar"> -->
					<Multiselect 
						v-model="auxiliary" 
						tag-placeholder="Add" 
						placeholder="Aux" 
						label="name" track-by="code" 
						:options="ObjAuxiliary" 
						:multiple="false" 
						:taggable="true" 
						@tag="addTag"
					></Multiselect>
				</div>
				<button @click="stateOfProject()" class="ml-2 px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-green-600 border border-transparent rounded-lg active:bg-blue-500 hover:bg-green-700 focus:outline-none focus:shadow-outline-purple">
					Buscar
				</button>
			</div>
			<div>
				<div class="text-right text-sm ">
					<button v-if="resAuxiliary.length > 0" @click="showModalAssociateAccount()" title="Asociar cuentas" class="ml-2 px-4 py-2 text-sm font-medium  text-white transition-colors duration-150 bg-green-600 border border-transparent rounded-lg active:bg-blue-500 hover:bg-green-700 focus:outline-none focus:shadow-outline-purple">
						<i class="icon-link-ext" aria-hidden="true"></i>
					</button>
					
					<button v-if="resAuxiliary.length > 0" @click="stateOfProject()" title="eliminar cuentas asociadas" class="ml-2 px-4 py-2 text-sm font-medium  text-white transition-colors duration-150 bg-red-600 border border-transparent rounded-lg active:bg-blue-500 hover:bg-red-700 focus:outline-none focus:shadow-outline-purple">
						<i class="icon-unlink" aria-hidden="true"></i>
					</button>
				</div>
			</div>
			
		</div>
		<div class="grid gap-3 mb-8 md:grid-cols-2 lg:grid-cols-2">
			<div>
				<!-- Card -->
				<div class="mb-3 flex items-center p-4 bg-white rounded-lg shadow dark:bg-gray-800" >
					<table v-if="codeAux != ''" class="w-full whitespace-no-wrap">
						
						<tbody
							class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800"
						>
							<tr class="text-gray-700 dark:text-gray-400">
								<td class="p-2 text-md text-left">
									<div class="inline-block">
										<span class="py-1 px-2 mr-4 text-yellow-500 bg-yellow-100 rounded-full dark:text-yellow-100 dark:bg-yellow-500" >
											<i class="icon-user-circle-o" aria-hidden="true"></i>
										</span>
										<span class="text-left">
											CLIENTE: {{clienteAux}}
										</span>
									</div>
									
								</td>
							</tr>
							<tr class="text-gray-700 dark:text-gray-400">
								<td class="p-2 text-md text-left ">
									<div class="inline-block">
										<span class="py-2 px-2 mr-4 text-green-500 bg-green-100 rounded-full dark:text-green-100 dark:bg-green-500" >
										<i class="icon-sitemap" aria-hidden="true"></i>
										</span>
										<span class="text-left">
											TIPO: {{contractType}} CODIGO: {{codeAux}}
										</span>
									</div>
								</td>
							</tr>
							<tr class="text-gray-700 dark:text-gray-400">
								<td class="p-2 text-md text-left ">
									<div class="inline-block">
										<span class="py-1 px-2 mr-4 text-blue-500 bg-blue-100 rounded-full dark:text-green-100 dark:bg-green-500" >
											<i class="icon-location" aria-hidden="true"></i>
										</span>
										<span class="text-left">
											DIRECCION: {{siteAux}}
										</span>
									</div>
									
								</td>
							</tr>

						</tbody>
					</table>
					<div v-else class="text-sm text-gray-300">
						Datos del proyecto
					</div>
				</div>
			</div>

			<div>
				<!-- Card Graphs -->
				<div class="flex block-inline items-center p-4 bg-white rounded-lg shadow dark:bg-gray-800" >
					<div v-if="graph1 >= 2" class="py-1 px-2 mr-4  font-semibold text-gray-700 dark:text-gray-200 text-center" >
						<div >
							<Bar
							:chart-options="chartOptions"
							:chart-data="chartDataIngrsos"
							:chart-id="chartId"
							:dataset-id-key="datasetIdKey"
							:plugins="plugins"
							:css-classes="cssClasses"
							:styles="styles"
							:width="width"
							:height="height"
						/>
						</div>
						
					</div>
					<div v-else class="text-sm text-gray-300">Estadisticas</div>
				</div>
			</div>
				
		</div>
		
		<div class="flex block-inline items-center p-4 bg-white rounded-lg shadow dark:bg-gray-800 mt-4" >
			<div class="py-1 px-2 mr-4 text-lg font-semibold text-gray-700 dark:text-gray-200 text-left" >
				INGRESOS ESPERADOS DEL PROYECTO: <span v-if="resAuxiliary.length >0"> {{resAuxiliary}} </span>
			</div>
			<div class="text-left flex block-inline w-2/5">
				
			</div>
		</div>
		<div class="w-full overflow-hidden rounded-lg shadow-xs ">
			<div class="w-full overflow-x-auto">
				<table class="w-full whitespace-no-wrap">
					<thead>
						<tr
							class="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800"
						>
							<th class="px-2 py-3 w-4/5">DETALLE</th>
							<th class="px-2 py-3 w-1/5 text-center ">SALDO</th>
						</tr>
					</thead>
					<tbody
						class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800"
					>
						<tr v-for="(item, index) in objAccount.accounts_totals" :key="index" class="text-gray-700 dark:text-gray-400">
							
							
							<td class="px-1 py-1 text-xs w-4/5 text-left">
								{{item.accountName}}
							</td>
							
							<td class="px-1 py-1 text-xs w-1/5">
								{{ item.credit - item.debit > 0 ? ` ${formatNumber(item.credit - item.debit, ',',  '$', true)} `  : `( ${formatNumber(((item.credit - item.debit)*-1), ',',  '$', true)} )` }}
							</td>
							
						</tr>

					</tbody>
				</table>
			</div>
		</div>

		<div class="flex block-inline items-center p-4 bg-white rounded-lg shadow dark:bg-gray-800 mt-8" >
			<div  class="py-1 px-2 mr-4 text-lg font-semibold text-gray-700 dark:text-gray-200 text-left" >
				DISPONIBILIDAD BANCARIA: <span v-if="resAuxiliary.length >0"> {{resAuxiliary}} </span>
			</div>
			<div class="text-left flex block-inline w-2/5">
				
			</div>
		</div>
		<div class="w-full overflow-hidden rounded-lg shadow-xs ">
			<div class="w-full overflow-x-auto">
				<div>
					<table class="w-full whitespace-no-wrap">
					<thead>
						<tr
							class="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800"
						>
							<th class="px-2 py-3 w-24">N. Asiento</th>
							<th class="px-2 py-3 w-24">Fecha</th>
							<th class="px-2 py-3">Referencia</th>
							<th class="px-2 py-3">Descripcion</th>
							<th class="px-2 py-3 w-28">Debito</th>
							<th class="px-2 py-3 w-28">Credito</th>
							<th class="px-2 py-3 w-28">Saldo</th>
						</tr>
					</thead>
					<tbody
						class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800"
					>
						<tr v-for="(item, index) in ObjAccountLedger.CxB.account_transactions" :key="index" class="text-gray-700 dark:text-gray-400">
							<td class="py-2 px-4 text-xs text-left ">
								<router-link :to="{name: 'NumberAccTrans', params: {url: 'entrynumber', number: item.accounting_entry_id}}" class=" border-2 py-1 px-2 border-gray-300 rounded hover:bg-gray-200" >
									{{item.accounting_entry_id}}
								</router-link>
							</td>
							<td class="px-4 text-xs text-left">
								{{item.transactionDate}}
							</td>
							
							<td class="px-4 text-sm text-left">
								( {{item.reference_type}}:{{item.reference}} )
							</td>
							<td class="px-4 text-sm text-left">
								{{item.description}}
							</td>
							<td class="px-4 text-xs">
								{{item.debit > 0 ?  formatNumber(item.debit, ',',  '$', true) : ' ' }}
							</td>
							<td class="px-4 text-xs">
								{{item.credit > 0 ?  formatNumber(item.credit, ',',  '$', true) : ' '}}
							</td>
							<td class="px-4 text-xs">
								{{item.progressive_balance  > 0 
								? formatNumber(item.progressive_balance, ',',  '$', true) 
								: `( ${formatNumber(item.progressive_balance, ',',  '$', true)} )`
								
								}}
							</td>
							
						</tr>
						<tr class="text-gray-700 dark:text-gray-400 bg-gray-200">
							<td class="py-2 px-4 text-xs text-left ">
								
							</td>
							<td class="px-4 text-xs text-left">
								
							</td>
							
							<td class="px-4 text-sm text-left">
							</td>
							<td class="px-4 text-sm text-right font-semibold">
								Total
							</td>
							<td class="px-4 text-xs font-semibold  ">
								{{ObjAccountLedger.CxB.total_debit > 0 ?  formatNumber(ObjAccountLedger.CxB.total_debit, ',',  '$', true) : ' ' }}
							</td>
							<td class="px-4 text-xs font-semibold  ">
								{{ObjAccountLedger.CxB.total_credit > 0 ?  formatNumber(ObjAccountLedger.CxB.total_credit, ',',  '$', true) : ' '}}
							</td>
							<td class="px-4 text-xs">
							</td>
							
						</tr>
					</tbody>
				</table>
				</div>
			</div>
		</div>
		<div class="flex block-inline items-center p-4 bg-white rounded-lg shadow dark:bg-gray-800 mt-8" >
			<div class="px-2 mr-4 text-lg font-semibold text-gray-700 dark:text-gray-200 text-left" >
				CUENTAS POR COBRAR: <span v-if="resAuxiliary.length >0"> {{resAuxiliary}} </span>
			</div>
			<div class="text-left flex block-inline w-2/5">
				
			</div>
		</div>
		<div class="mb-8 w-full overflow-hidden rounded-lg shadow-xs ">
			<div class="w-full overflow-x-auto">
				<div>
					<table class="w-full whitespace-no-wrap">
						<thead>
							<tr
								class="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800"
							>
								<th class="px-2 py-3 w-24">N. Asiento</th>
								<th class="px-2 py-3 w-24">Fecha</th>
								<th class="px-2 py-3">Referencia</th>
								<th class="px-2 py-3">Descripcion</th>
								<th class="px-2 py-3 w-28">Debito</th>
								<th class="px-2 py-3 w-28">Credito</th>
								<th class="px-2 py-3 w-28">Saldo</th>
							</tr>
						</thead>
						<tbody
							class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800"
						>
							<tr v-for="(item, index) in ObjAccountLedger.CxC.account_transactions" :key="index" class="text-gray-700 dark:text-gray-400">
								<td class="py-2 px-4 text-xs text-left ">
									<router-link :to="{name: 'NumberAccTrans', params: {url: 'entrynumber', number: item.accounting_entry_id}}" class=" border-2 py-1 px-2 border-gray-300 rounded hover:bg-gray-200" >
										{{item.accounting_entry_id}}
									</router-link>
								</td>
								<td class="px-4 text-xs text-left">
									{{item.transactionDate}}
								</td>
								
								<td class="px-4 text-sm text-left">
									( {{item.reference_type}}:{{item.reference}} )
								</td>
								<td class="px-4 text-sm text-left">
									{{item.description}}
								</td>
								<td class="px-4 text-xs">
									{{item.debit > 0 ?  formatNumber(item.debit, ',',  '$', true) : ' ' }}
								</td>
								<td class="px-4 text-xs">
									{{item.credit > 0 ?  formatNumber(item.credit, ',',  '$', true) : ' '}}
								</td>
								<td class="px-4 text-xs">
									{{item.progressive_balance  > 0 
									? formatNumber(item.progressive_balance, ',',  '$', true) 
									: `( ${formatNumber(item.progressive_balance, ',',  '$', true)} )`
									
									}}
								</td>
								
							</tr>
							<tr class="text-gray-700 dark:text-gray-400 bg-gray-200">
								<td class="py-2 px-4 text-xs text-left ">

								</td>
								<td class="px-4 text-xs text-left">
									
								</td>
								
								<td class="px-4 text-sm text-left">
									
								</td>
								<td class="px-4 text-sm font-semibold text-right">
									Total
								</td>
								<td class="px-4 font-semibold text-xs">
									{{ObjAccountLedger.CxC.total_debit > 0 ?  formatNumber(ObjAccountLedger.CxC.total_debit, ',',  '$', true) : ' ' }}
								</td>
								<td class="px-4 font-semibold text-xs">
									{{ObjAccountLedger.CxC.total_credit > 0 ?  formatNumber(ObjAccountLedger.CxC.total_credit, ',',  '$', true) : ' '}}
								</td>
								<td class="px-4 text-xs">
								
								</td>
								
							</tr>

						</tbody>
					</table>
				</div>
			</div>
		</div>

		<div class="flex block-inline items-center p-4 bg-white rounded-lg shadow dark:bg-gray-800 mt-8" >
			<div class="px-2 mr-4 text-lg font-semibold text-gray-700 dark:text-gray-200 text-left" >
				COSTOS INCURRIDOS: <span v-if="resAuxiliary.length > 0"> {{resAuxiliary}} </span> 
			</div>
			<div v-for="(item, index) in LinkCxI" :key="index">
				<button  @click="removeAccountRelatedAccount(item)" title="eliminar cuentas asociadas" class="ml-2 px-4 py-2 text-sm font-medium  text-white transition-colors duration-150 bg-red-600 border border-transparent rounded-lg active:bg-blue-500 hover:bg-red-700 focus:outline-none focus:shadow-outline-purple">
						<i class="icon-unlink" aria-hidden="true"></i>
				</button>
			</div>
		</div>
		
		<div class="text-left flex block-inline mb-6">
			<table class="w-full whitespace-no-wrap">
				<thead>
					<tr
						class="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800"
					>
						
						<th class="px-2 py-3">Descripcion</th>
						<th class="px-2 py-3 w-28">Debito</th>
						<th class="px-2 py-3 w-28">Credito</th>
						<th class="px-2 py-3 w-28">Saldo</th>
					</tr>
				</thead>
				<tbody
					class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800"
				>
					<tr v-for="(item, index) in ObjAccountLedger.CxI" :key="index" class="text-gray-700 dark:text-gray-400">
						
						<td class="px-4 text-sm text-left">
							{{item.accountCode}} - {{item.accountName}}
						</td>
						<td class="px-4 text-xs">
							{{item.total_transactions_debit > 0 ?  formatNumber(item.total_transactions_debit, ',',  '$', true) : ' ' }}
						</td>
						<td class="px-4 text-sm">
							{{item.total_transactions_credit > 0 ?  formatNumber(item.total_transactions_credit, ',',  '$', true) : ' '}}
						</td>
						<td class="px-4 text-sm">
							
						</td>
					</tr>
					<tr class="text-gray-700 dark:text-gray-400 bg-gray-200">
						
						<td class="px-4 text-sm text-right font-semibold">
							Total
						</td>
						<td class="px-4 text-xs font-semibold">
							{{totalCostsIncurredDebit > 0 ?  formatNumber(totalCostsIncurredDebit, ',',  '$', true) : ' ' }}
						</td>
						<td class="px-4 text-xs font-semibold">
							{{totalCostsIncurredCredit > 0 ?  formatNumber(totalCostsIncurredCredit, ',',  '$', true) : ' ' }}
							
						</td>
						<td class="px-4 text-xs font-semibold">
							{{totalCostsIncurredDebit - totalCostsIncurredCredit > 0 ?  formatNumber(totalCostsIncurredDebit - totalCostsIncurredCredit, ',',  '$', true) : ' ' }}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="flex block-inline items-center p-4 bg-white rounded-lg shadow dark:bg-gray-800 mt-8" >
			<div class="px-2 mr-4 text-lg font-semibold text-gray-700 dark:text-gray-200 text-left" >
				PASIVO TARJETAS DE CREDITO: <span v-if="resAuxiliary.length > 0"> {{resAuxiliary}} </span> 
			</div>
		</div>
		<div class="text-left flex block-inline mb-6">
			<table class="w-full whitespace-no-wrap">
				<thead>
					<tr
						class="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800"
					>
						<th class="p-2">Detalles</th>
					</tr>
				</thead>
				<tbody
					class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800"
				>
					<tr v-for="(item, index) in PxTC" :key="index" class="text-gray-700 dark:text-gray-400">
						
						<td class="px-4 text-xs text-left">
							<div class="w-full mt-4 text-sm font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800">
								{{item.accountName}} 
								<button  @click="removeAccountRelatedAccount(item.id)" title="eliminar cuentas asociadas" class="ml-2 px-4 py-2 text-sm font-medium  text-white transition-colors duration-150 bg-red-600 border border-transparent rounded-lg active:bg-blue-500 hover:bg-red-700 focus:outline-none focus:shadow-outline-purple">
										<i class="icon-unlink" aria-hidden="true"></i>
								</button>
								<!-- {{item.id}} -->
							</div>
							<table class="w-full whitespace-no-wrap ">
								<thead>
									<tr
										class="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800"
									>
										<th class="px-2 py-3 w-24">N. Asiento</th>
										<th class="px-2 py-3 w-24">Fecha</th>
										<th class="px-2 py-3">Referencia</th>
										<th class="px-2 py-3">Descripcion</th>
										<th class="px-2 py-3 w-28">Debito</th>
										<th class="px-2 py-3 w-28">Credito</th>
										<th class="px-2 py-3 w-28">Saldo</th>
									</tr>
									</thead>
									<tbody
										class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800"
									>
									<tr v-for="(subitem, index) in item.account_transactions" :key="index" class="text-gray-700 dark:text-gray-400">
										<td class="py-2 px-4 text-xs text-left ">
											<router-link :to="{name: 'NumberAccTrans', params: {url: 'entrynumber', number: subitem.accounting_entry_id}}" class=" border-2 py-1 px-2 border-gray-300 rounded hover:bg-gray-200" >
												{{subitem.accounting_entry_id}}
											</router-link>
										</td>
										<td class="px-4 text-xs text-left">
											{{subitem.transactionDate}}
										</td>
										
										<td class="px-4 text-sm text-left">
											( {{subitem.reference_type}}:{{subitem.reference}} )
										</td>
										<td class="px-4 text-sm text-left">
											{{subitem.description}}
										</td>
										<td class="px-4 text-xs">
											{{subitem.debit > 0 ?  formatNumber(subitem.debit, ',',  '$', true) : ' ' }}
										</td>
										<td class="px-4 text-sm">
											{{subitem.credit > 0 ?  formatNumber(subitem.credit, ',',  '$', true) : ' '}}
										</td>
										<td class="px-4 text-sm">
											{{subitem.progressive_balance  > 0 
											? formatNumber(subitem.progressive_balance, ',',  '$', true) 
											: `( ${formatNumber(subitem.progressive_balance, ',',  '$', true)} )`
											
											}}
										</td>
										
									</tr>
								</tbody>
							</table>
						</td>
						
					</tr>
				</tbody>
			</table>
		</div>
		<div class="flex block-inline items-center p-4 bg-white rounded-lg shadow dark:bg-gray-800 mt-8" >
			<div class="px-2 mr-4 text-lg font-semibold text-gray-700 dark:text-gray-200 text-left" >
				CUENTAS POR PAGAR: <span v-if="resAuxiliary.length > 0"> {{resAuxiliary}} </span> 
			</div>
		</div>
		<div class="text-left flex block-inline mb-6">
			<table class="w-full whitespace-no-wrap">
				<thead>
					<tr
						class="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800"
					>
						<th class="p-2">Detalles</th>
					</tr>
				</thead>
				<tbody
					class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800"
				>
					<tr v-for="(item, index) in CxP" :key="index" class="text-gray-700 dark:text-gray-400">
						
						<td class="px-4 text-xs text-left">
							<div class="w-full mt-4 text-sm font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800">
								{{item.accountName}}
							</div>
							<table class="w-full whitespace-no-wrap ">
								<thead>
									<tr
										class="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800"
									>
										<th class="px-2 py-3 w-24">N. Asiento</th>
										<th class="px-2 py-3 w-24">Fecha</th>
										<th class="px-2 py-3">Referencia</th>
										<th class="px-2 py-3">Descripcion</th>
										<th class="px-2 py-3 w-28">Debito</th>
										<th class="px-2 py-3 w-28">Credito</th>
										<th class="px-2 py-3 w-28">Saldo</th>
									</tr>
									</thead>
									<tbody
										class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800"
									>
									<tr v-for="(subitem, index) in item.account_transactions" :key="index" class="text-gray-700 dark:text-gray-400">
										<td class="py-2 px-4 text-xs text-left ">
											<router-link :to="{name: 'NumberAccTrans', params: {url: 'entrynumber', number: subitem.accounting_entry_id}}" class=" border-2 py-1 px-2 border-gray-300 rounded hover:bg-gray-200" >
												{{subitem.accounting_entry_id}}
											</router-link>
										</td>
										<td class="px-4 text-xs text-left">
											{{subitem.transactionDate}}
										</td>
										
										<td class="px-4 text-sm text-left">
											( {{subitem.reference_type}}:{{subitem.reference}} )
										</td>
										<td class="px-4 text-sm text-left">
											{{subitem.description}}
										</td>
										<td class="px-4 text-xs">
											{{subitem.debit > 0 ?  formatNumber(subitem.debit, ',',  '$', true) : ' ' }}
										</td>
										<td class="px-4 text-sm">
											{{subitem.credit > 0 ?  formatNumber(subitem.credit, ',',  '$', true) : ' '}}
										</td>
										<td class="px-4 text-sm">
											{{subitem.progressive_balance  > 0 
											? formatNumber(subitem.progressive_balance, ',',  '$', true) 
											: `( ${formatNumber(subitem.progressive_balance, ',',  '$', true)} )`
											
											}}
										</td>
										
									</tr>
								</tbody>
							</table>
						</td>
						
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>

import Axios from 'axios'
import Multiselect from 'vue-multiselect'
import { mapState } from 'vuex'
import Btn from '@/components/util/btnBasic.vue'
import { Bar } from "vue-chartjs/legacy";
import {
	Chart as ChartJS,
	Title,
	Tooltip,
	Legend,
	BarElement,
	CategoryScale,
	LinearScale,
} from "chart.js";
ChartJS.register(
	Title,
	Tooltip,
	Legend,
	BarElement,
	CategoryScale,
	LinearScale
);


// import Swal from 'sweetalert2'
// import { mapState } from 'vuex'
	export default {
		name: 'ContentStatusOfProject',
		components: {
			Multiselect,
			Btn,
			Bar,
		},
		props: {
			chartId: {
				type: String,
				default: 'bar-chart'
			},
			datasetIdKey: {
				type: String,
				default: 'label'
			},
			width: {
				type: Number,
				default: 400
			},
			height: {
				type: Number,
				default: 210
			},
			cssClasses: {
				default: '',
				type: String
			},
			styles: {
				type: Object,
				default: () => {}
			},
			plugins: {
				type: Array,
				default: () => []
			}
		},
		data() {
			return {
				graph1: 0,
				URL: process.env.VUE_APP_URL_ACC,
				URLAdm: process.env.VUE_APP_URL,
				objAccountSelectAccounts:[],
				objAccount:[],
				codeAux:'',
				siteAux:'',
				clienteAux:'',
				account:'',
				subdescriptor:'',
				contractType:'',
				related_account_id:'',
				ObjAccountLedger:{
					CxC:'',
					CxB:'',
					CxI:'',
				},
				LinkCxI:[],
				PxTC:[],
				CxP:[],
				ObjAuxiliary:'',
				auxiliary:'',
				resAuxiliary:[],
				dateFrom:'',
				totalCostsIncurredDebit:0,
				totalCostsIncurredCredit:0,
				chartDataIngrsos: {
					labels: [
						"Presupuestado",
						"Cobrado",
						"Gastos",
					
					],
					datasets: [
						{
							label: "Estadistica de proyecto",
							backgroundColor: "#f87979",
							data: [0, 0, 0],
						},
					],
				},
				chartOptions: {
					responsive: true,
					maintainAspectRatio: false,
				},
				
			}
		},
		computed: {
			...mapState(['user','access_token'])
		},
		mounted() {
			this.getAccTransaction()
			this.getAuxiliary()
		},
		
		methods: {
			addTag (newTag) {
                const tag = {
                    name: newTag,
                    code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
                }
                this.options.push(tag)
                this.transaction.push(tag)
            },
			formatNumber(number, separator = ',', symbol='$', showSymbol = false){
				number = parseFloat(number)
				number = number.toFixed(2)
				if (separator == ',') {
					let montoNuevo = number.toString().replace(/\D/g, "")
					.replace(/([0-9])([0-9]{2})$/, '$1.$2')
					.replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ",");
					if (showSymbol) {
						return `${symbol}${montoNuevo}`
					} else {
						return `${montoNuevo}`	
					}
				} else {
					let montoNuevo = number.toString().replace(/\D/g, "")
					.replace(/([0-9])([0-9]{2})$/, '$1,$2')
					.replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
					if (showSymbol) {
						return `${symbol}${montoNuevo}`
					} else {
						return `${montoNuevo}`	
					}
				}
			},
			showModalAssociateAccount(){
				this.$modal.show('associateAccount')
			},
			async getAccTransaction() {
				const URL = `${this.URL}showAllCompanyAccountsFlatMode`
				const fetchConfig = {
                    headers: {
                        Accept : 'application/json',
                        "Content-Type": 'application/json'
                        // Authorization : `Bearer ${this.access_token.replace(/['"]+/g, '')}`	
                    }
				}
				let params = {
					company_id: this.user.companyId
				}
				
				// try {
					let rsAccount = await Axios.post(URL, params, fetchConfig)
					// console.log(rsAccount.data.data)
					const result = rsAccount.data.data;
					// console.log(result)
					this.objAccountSelectAccounts = result.map(item => {
							return { name: item.accountCode + ' - ' + item.accountName, code: item.id}
						// return { text: item.accountName, value: item.id}
					})
				
			},
			async getAuxiliary() {
				const URL = `${this.URLAdm}v1/contract`
				const fetchConfig = {
                    headers: {
                        Accept : 'application/json',
                        "Content-Type": 'application/json',
                        Authorization : `Bearer ${this.access_token.replace(/['"]+/g, '')}`	
                    }
				}
				let params = {
					companyId: this.user.companyId
				}
				// console.log(params)
				// try {
					let rsAccount = await Axios.post(URL, params, fetchConfig)
					// console.log(rsAccount)
					// return
					const result = rsAccount.data;
					// console.log(result)
					this.ObjAuxiliary = result.map(item => {
						// console.log(item.client.clientName)
							return { name: item.contractNumber + ' - ' + item.siteAddress, code: item.contractNumber +'%'+ item.siteAddress +'%'+ item.client.clientName +'%'+ item.contractType}
						// return { text: item.accountName, value: item.id}
					})
				
			},
			async removeAccountRelatedAccount(item) {
				console.log(item)
				const URL = `${this.URL}removeAccountRelatedAccount`
				const fetchConfig = {
                    headers: {
                        Accept : 'application/json',
                        "Content-Type": 'application/json',
                        // Authorization : `Bearer ${this.access_token.replace(/['"]+/g, '')}`	
                    }
				}
				let params = {
					id: item
				}
				// console.log(params)

					let rsAccount = await Axios.post(URL, params, fetchConfig)
					console.log(rsAccount)
					alert(rsAccount.data.data.message)
					this.stateOfProject()
			},
			async stateOfProject(){
				this.codeAux = '';
				this.siteAux = '';
				this.clienteAux = '';
				this.LinkCxI = [];

				let auxArray = this.auxiliary.code.split("%");
				// console.log(auxArray)
				this.codeAux = auxArray[0]
				this.siteAux = auxArray[1]
				this.clienteAux = auxArray[2]
				let contractType = auxArray[3]
				this.contractType = contractType ==='P' ? 'PROYECTO': 'SERVICIO'
				// return
				this.ObjAccountLedger = {
					CxC:'',
					CxB:'',
					CxI:'',
				}
				this.PxTC = []
				this.CxP = []
				this.chartDataIngrsos.datasets[0].data[0] = 0
				this.chartDataIngrsos.datasets[0].data[1] = 0
				this.chartDataIngrsos.datasets[0].data[2] = 0
				this.graph1 = 0
				
				let params = {
					auxiliary:  this.codeAux,
					company_id: this.user.companyId,
					accountGroup: 4,
				}
				const fetchConfig = {
                    headers: {
                        Accept : 'application/json',
                        "Content-Type": 'application/json',
                    }
				}

				let URL = this.URL;

				try {
				
					const res = await Axios.post(`${URL}stateOfProject`, params, fetchConfig)

					// asigno las cuentas de ingresos esperados
					if (res.statusText ==='OK') {
						this.objAccount = res.data.data //selecciona las cuentas de ingresos esperados
						this.resAuxiliary = this.objAccount.auxiliary
						// console.log(this.objAccount)
						this.chartDataIngrsos.datasets[0].data[0] = parseFloat(this.objAccount.accounts_totals[0].credit) //asigno el monto de ingreso a la grafica
						// console.log(this.chartDataIngrsos.datasets[0].data[0])
					}
					// console.log(res.data.data.accounts_totals)
					let searchReleated = res.data.data.accounts_totals
					searchReleated.forEach(element => { //uso un for para buscar si alguna cuenta tiene otra cuenta relacionada
						let params2 = {
							account_id: element.account_id,
							descriptor:  this.codeAux,
						}
						// consulto si la cuenta, tiene otras cuentas relacinadas
						Axios.post(`${URL}AccountRelatedAccounts`, params2, fetchConfig).then((result) => {
							console.log(result)

							
							let getAccountRelated = result.data.data.relatedAccounts
							if (getAccountRelated.length > 0) {
								
								// console.log(getAccountRelated)
								const URL = `${this.URL}AccountTransactions`
								const URL1 = `${this.URL}projectCostByAccountDephtAndAuxiliar`
								const fetchConfig = {
										headers: {
											Accept : 'application/json',
											"Content-Type": 'application/json',
											// Authorization : `Bearer ${this.access_token.replace(/['"]+/g, '')}`	
										}
									}
								let i =0 // contador para el array de PxTC
								getAccountRelated.forEach(element => { // for, para buscar los mayores de las cuentas relacionadas
									let params3 = {
										account_id: element.id, //id de la cuenta relacionada
										auxiliary: this.codeAux,
									}
									// return
									if (element.pivot.subdescriptor === 'CxI'){
										Axios.post(URL1, params3, fetchConfig).then((result) => {
											// console.log(result)
											if (result.data.data.length > 0) {
												this.LinkCxI.push(element.pivot.id) //utilizado para identificar los id de las cuentas relacionadas
												this.ObjAccountLedger[element.pivot.subdescriptor] = result.data.data //agrego una propiedad al objeto con el nombre de la "subdescriptor"	
												// console.log(this.ObjAccountLedger[element.pivot.subdescriptor])
												// console.log(element.pivot.id)
												const DEBIT = this.ObjAccountLedger[element.pivot.subdescriptor].map(item => parseFloat(item.total_transactions_debit)).reduce((prev, curr) => prev + curr, 0);
												const CREDIT = this.ObjAccountLedger[element.pivot.subdescriptor].map(item => parseFloat(item.total_transactions_credit)).reduce((prev, curr) => prev + curr, 0);
												this.chartDataIngrsos.datasets[0].data[2] = DEBIT
												this.graph1 += 1
												this.totalCostsIncurredDebit = DEBIT
												this.totalCostsIncurredCredit = CREDIT
												// console.log(DEBIT)
											}
									})
									}
									if (element.pivot.subdescriptor === 'CxC'){
										// si existe una cuenta relacinada, obtengo el mayor de esa cuenta
										Axios.post(URL, params3, fetchConfig).then((result) => {
											
											//agrego una propiedad al objeto con el nombre de la "subdescriptor"
											this.ObjAccountLedger[element.pivot.subdescriptor] = result.data.data
											// console.log(this.ObjAccountLedger[element.pivot.subdescriptor])
											if (result.data.data.total_balance > 0) {
												let resulCobrado = this.chartDataIngrsos.datasets[0].data[0] - result.data.data.total_balance
												this.chartDataIngrsos.datasets[0].data[1] = resulCobrado
												this.graph1 += 1
											}
											
										})
									}
									if (element.pivot.subdescriptor === 'CxB'){
										Axios.post(URL, params3, fetchConfig).then((result) => {
											//agrego una propiedad al objeto con el nombre de la "subdescriptor"
											this.ObjAccountLedger[element.pivot.subdescriptor] = result.data.data
											// console.log(this.ObjAccountLedger[element.pivot.subdescriptor])
										})
									}
									if (element.pivot.subdescriptor === 'PxTC'){
										Axios.post(URL, params3, fetchConfig).then((result) => {
											//agrego una propiedad al objeto con el nombre de la "subdescriptor"
											console.log(element.pivot.id)
											this.PxTC.push(result.data.data)
											console.log(element.pivot.id)
											console.log(this.PxTC)
											i++
										})
									}
									if (element.pivot.subdescriptor === 'CxP'){
										Axios.post(URL, params3, fetchConfig).then((result) => {
											// console.log(result.data.data)
											//agrego una propiedad al objeto con el nombre de la "subdescriptor"
											this.CxP.push(result.data.data)
											// console.log(this.PxTC)
											i++
										})
									}
									
								});
							}
							// console.log(this.ObjAccountLedger)
						})
					});
				} catch (error) {
						// console.log(error.response)
						if (error.response.status === 422) {
							alert(error.response.statusText)
						}
					// if (error.message == 'Request failed with status code 422') {
					// 	this.saving = false
					// 	alert(error.response.data.data.message)
					// } 
				}
				
			},
			associateAccount(){
				if (this.related_account_id ==='' && this.subdescriptor ==='' ) {
					return
				}
				const URL = `${this.URL}storeAccountRelatedAccount`
				const fetchConfig = {
                    headers: {
                        Accept : 'application/json',
                        "Content-Type": 'application/json'
                        // Authorization : `Bearer ${this.access_token.replace(/['"]+/g, '')}`	
                    }
				}
				let params = {
						base_account_id: this.objAccount.accounts_totals[0].account_id,
						related_account_id:  this.related_account_id.code,
						descriptor:  this.codeAux,
						subdescriptor:  this.subdescriptor,
					}
					// console.log(params)
					// return
				this.$modal.hide('associateAccount')
				Axios.post(URL, params, fetchConfig).then((result) => {
					if (result.statusText === 'OK') {
						this.stateOfProject()	
					}
					
					// console.log(element.pivot.subdescriptor) //agrego una propiedad al objeto con el nombre de la "subdescriptor"
					// this.ObjAccountLedger[element.pivot.subdescriptor] = result.data.data
					
				})
			},
		},
		watch: {

        }	
	}
</script>

<style >

</style>